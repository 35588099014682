.err-msg{
    color:red;
    font-size: 12px;
}
a{
    color:inherit !important;
}
.cke{
    width:100% !important;
}
.feather{
    cursor: pointer;
}
.Toastify__toast--success {
    color: #07bc0c !important;
    background: white !important;
}
.Toastify__progress-bar--success{
    background: #07bc0c !important;
}
.Toastify__close-button--success{
    color: #07bc0c !important;
}
.Toastify__toast--error {
    color: #e74c3c !important;
    background: white !important;
}
.Toastify__progress-bar--error{
    background: #e74c3c !important;
}
.Toastify__close-button--error{
    color: #e74c3c !important;
}
.Toastify__toast--warning {
    color: #f1c40f !important;
    background: white !important;
}
.Toastify__progress-bar--warning{
    background: #f1c40f !important;
}
.Toastify__close-button--warning{
    color: #f1c40f !important;
}
.Toastify__toast--info {
    color: #3498db !important;
    background: white !important;
}
.Toastify__progress-bar--info{
    background: #3498db !important;
}
.Toastify__close-button--info{
    color: #3498db !important;
}
.active-btn{
    padding:3px 10px;
}
.inactive-btn{
    padding:3px 10px;
}